/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Kažkaip ataskaita pasimirė tik man padirbus kontinentuose, nes nu\nvisiškai aš ten išsisunkiau. Nėra taip, kad būtų ten labai blogai buvę.\nTiesą pasakius, gal net ir visai normaliai, nes dirbom kokybiškai ir\nstengėmės išspaust kuo stipresnį rezultatą. Ir netgi išspaudėm. Visa\nproblema ten buvo žmonėse. Per pusmetį sutikau tik vieną žmogų, kuris,\natrodo, buvo nepasidavęs ir nelaikė savęs tiesiog įrankiu. Visi kiti\nbuvo visiškai mirę viduje be jokios aistros ir nelabai suprantatys savo\npoelgių ir darbų prasmę."), "\n", React.createElement(_components.p, null, "Vėliau kažkaip taip gavosi, kad išbėgau dirbt į savo dabartinę\ndarbovietę ir grįžau atgal į gimtinę. Tada metus su čiut čiut visiškai\nnieko įdomaus nenutiko ir nelabai tikriausiai kažkas ir galvojosi.\nNesigalvojo tikriausiai dėl to, kad naujoj vietoj irgi ten biškį toks\ngamyklinis pragarėlis virė."), "\n", React.createElement(_components.p, null, "Tada prasidėjo lėbavimo periodas, kuris šiaip jau buvo labai smagus. Dar\ndabar būna, kad su kokiu jaunesniu žmogum kalbėdamas parekomenduoju tokį\nužsiėmimą, kol dar įsipareigojimų neturi. Žiauriai smagu buvo. Dar buvo\nir šiltnamio statybos tuo metu, apie kurį aš čia kažkur metais-dviem\nanksčiau ataskaitoj rašiau."), "\n", React.createElement(_components.p, null, "Aišku ten dar buvo ir visokių atradimų, sielos džiaugsmų ir tokių\ncharakterį-stiprinančių atsisveikinimų ir parodymų koks aš kartais\nlochas. Nu ir taip rinse-and-repeat kažkur apie pusantrų metų."), "\n", React.createElement(_components.p, null, "Tada buvo metai kitokio džiugesio ir susidegino lygiai vienas tiltas.\nTada kažkaip viskas geryn geryn ir here we are. Žiūrėsim kiek ilgai čia\nšita mano ataskaita gyvens. Nu ir dar beveik dantis sutvarkiau. Ate."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
